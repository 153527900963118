@import "variables";

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "bootswatch";

body,
html,
#root {
    height: 100%;
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-bold {
    font-weight: bold;
}

.flex-1 {
    flex: 1;
}

.dropdown-menu-right {
    right: 0;
    left: auto !important;
}
